<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 class="mx-auto">
            Welcome {{name}}
          </h3>
        </v-card-title>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  computed: {
    name: {
      get() {
        if (this.$store.state.user.currentUser.name === undefined || this.$store.state.user.currentUser.name === null) {
          return this.$store.state.user.currentUser.email;
        }
        return this.$store.state.user.currentUser.name;
      },
      set(newName) {
        return newName;
      },
    },
  },
};
</script>
